import { configureStore } from "@reduxjs/toolkit"
import { authReducer } from "./slices/authSlice"
import { dataModelEditReducer } from "./slices/dataModelEditSlice"
import { commandReducer } from "./slices/commandSlice"
import { cpesReducer } from "./slices/cpesSlice"
import { managementConfigReducer } from "./slices/managementConfigSlice"
import { parentalControlConfigReducer } from "./slices/parentalControlSlice"
import { hostsReducer } from "./slices/hostsSlice"
import { bssReducer } from "./slices/bssSlice"
import { radiosReducer } from "./slices/radioSlice"
import { settingsReducer } from "./slices/settingsSlice"
import { bandsteeringReducer } from "./slices/bandsteeringSlice"
import { clientConnectivityEventsReducer } from "./slices/clientConnectivitySlice"
import { channelProfilesReducer } from "./slices/channelProfilesSlice"

export const store = configureStore({
    reducer: {
        auth: authReducer,
        dataModelEdit: dataModelEditReducer,
        commands: commandReducer,
        cpes: cpesReducer,
        managementConfig: managementConfigReducer,
        parentalControlConfig: parentalControlConfigReducer,
        hostsInfo: hostsReducer,
        bss: bssReducer,
        radios: radiosReducer,
        bandsteering: bandsteeringReducer,
        settings: settingsReducer,
        clientConnectivityEvents: clientConnectivityEventsReducer,
        channelProfiles: channelProfilesReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
