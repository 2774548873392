import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"
import { WifiBand } from "./radioSlice"
import { DayOfWeek } from "../types/dayOfWeek"

export interface ChannelProfile {
    channel: number
    bandwidth: number
    fitnessValue: number
    observationCount: number
    lastObservationDate: string
}

export interface HourlyChannelProfiles {
    hourId: number
    channelProfiles: ChannelProfile[]
}

export interface BandChannelProfiles {
    band: WifiBand
    hourlyProfiles: HourlyChannelProfiles[]
}

export interface DayOfWeekChannelProfiles {
    dayOfWeek: DayOfWeek
    bandProfiles: BandChannelProfiles[]
}

export interface ChannelProfilesData {
    serialNumber: string | null
    loading: boolean
    lastError: string | null
    profiles: DayOfWeekChannelProfiles[] | null
}

function createInitialState(): ChannelProfilesData {
    return {
        serialNumber: null,
        loading: false,
        lastError: null,
        profiles: null,
    }
}

interface GetChannelProfilesPayload {
    serialNumber: string
}

interface GetChannelProfilesResponse {
    serialNumber: string
    dayOfWeekProfiles: DayOfWeekChannelProfiles[]
}

export const getChannelProfiles = createAsyncThunk(
    "getChannelProfiles",
    async (payload: GetChannelProfilesPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/acs/cpes/${payload.serialNumber}/channel-profiles`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as GetChannelProfilesResponse
    },
)

export const channelProfilesSlice = createSlice({
    name: "channelProfiles",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getChannelProfiles.pending, (state, action) => {
            const serialNumber = action.meta.arg.serialNumber
            if (state.serialNumber !== serialNumber) {
                return {
                    ...createInitialState(),
                    serialNumber: serialNumber,
                    loading: true,
                }
            }
            state.loading = true
        })
        builder.addCase(getChannelProfiles.fulfilled, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.profiles = action.payload.dayOfWeekProfiles
            state.loading = false
            state.lastError = null
        })
        builder.addCase(getChannelProfiles.rejected, (state, action) => {
            if (state.serialNumber !== action.meta.arg.serialNumber) {
                return
            }
            state.loading = false
            state.lastError = "Failed to get channel profiles"
        })
    },
})

export const channelProfilesReducer = channelProfilesSlice.reducer

export const selectChannelProfiles = (state: RootState) => state.channelProfiles
