import { useState } from "react"
import { useDispatch } from "react-redux"
import {
    Button,
    Checkbox,
    DropdownItemProps,
    Form,
    FormDropdown,
    FormField,
    FormGroup,
    Header,
    Segment,
} from "semantic-ui-react"
import { UpdateRadioParams, WifiRadio, updateRadio } from "../store/slices/radioSlice"
import { AppDispatch } from "../store/store"
import { listToDropdown } from "../utils/componentHelpers"

interface WifiRadioViewProps {
    serialNumber: string
    radio: WifiRadio
}

export const WifiRadioView = ({ serialNumber, radio }: WifiRadioViewProps) => {
    const dispatch = useDispatch<AppDispatch>()
    const [dirty, setDirty] = useState(false)
    const [editData, setEditData] = useState<WifiRadio>({ ...radio })

    const updateData = (data: WifiRadio) => {
        setEditData(data)
        setDirty(true)
    }

    const commitRadioUpdate = () => {
        let updates: UpdateRadioParams = {}

        if (editData.channel !== radio.channel) {
            updates.channel = editData.channel
        }
        if (editData.configuredBandwidth !== radio.configuredBandwidth) {
            updates.configuredBandwidth = editData.configuredBandwidth
        }
        if (editData.enabled !== radio.enabled) {
            updates.enabled = editData.enabled
        }
        if (editData.autoChannelEnabled !== radio.autoChannelEnabled) {
            updates.autoChannelEnabled = editData.autoChannelEnabled
            updates.channel = editData.channel
        }

        dispatch(
            updateRadio({
                serialNumber: serialNumber,
                radioId: radio.id,
                data: updates,
            }),
        )
    }

    return (
        <Segment>
            <Header>{radio.band} Radio</Header>
            <Form>
                <FormField>
                    <label>Enable</label>
                    <Checkbox
                        checked={editData.enabled}
                        onChange={(ev, data) => updateData({ ...editData, enabled: !editData.enabled })}
                        toggle
                    />
                </FormField>
                <FormGroup>
                    <FormDropdown
                        label="Channel"
                        value={editData.channel}
                        key={`radio-${radio.id}-ch`}
                        compact
                        selection
                        scrolling
                        width={6}
                        options={listToDropdown(editData.possibleChannels)}
                        onChange={(_, data) => updateData({ ...editData, channel: data.value as number })}
                    />
                    <FormDropdown
                        label="Bandwidth"
                        value={editData.configuredBandwidth}
                        key={`radio-${radio.id}-bw`}
                        compact
                        selection
                        scrolling
                        width={6}
                        options={listToDropdown(editData.supportedBandwidths)}
                        onChange={(_, data) => updateData({ ...editData, configuredBandwidth: data.value as string })}
                    />
                </FormGroup>
                <FormField>
                    <label>Auto-Channel Selection</label>
                    <Checkbox
                        checked={editData.autoChannelEnabled}
                        onChange={(ev, data) =>
                            updateData({ ...editData, autoChannelEnabled: !editData.autoChannelEnabled })
                        }
                        toggle
                    />
                </FormField>

                {dirty && <Button onClick={commitRadioUpdate}>Save</Button>}
            </Form>
        </Segment>
    )
}
