import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    Button,
    Checkbox,
    Dimmer,
    Dropdown,
    Grid,
    GridRow,
    Header,
    Icon,
    Loader,
    Message,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from "semantic-ui-react"
import {
    checkBulkDataEnabled,
    disableBulkData,
    enableBulkData,
    selectManagementConfig,
} from "../store/slices/managementConfigSlice"
import { AppDispatch } from "../store/store"
import {
    ChannelProfile,
    DayOfWeekChannelProfiles,
    getChannelProfiles,
    HourlyChannelProfiles,
    selectChannelProfiles,
} from "../store/slices/channelProfilesSlice"
import { DayOfWeek } from "../store/types/dayOfWeek"
import { listToDropdown } from "../utils/componentHelpers"
import { WifiBand } from "../store/slices/radioSlice"

const bandDropdownOptions = listToDropdown(["2.4GHz", "5GHz", "6GHz"])
const dayOfWeekDropdownOptions = listToDropdown(Object.values(DayOfWeek))
const hourOfDayDropdownOptions = [...Array(24)].map((_, i) => {
    return {
        key: i,
        text: formatHourIdx(i),
        value: i,
    }
})

function formatHourIdx(hour: number): string {
    return `${hour.toString().padStart(2, "0")}:00`
}

function filterChannelProfiles(
    channelProfiles: DayOfWeekChannelProfiles[],
    band: WifiBand,
    dayOfWeek: DayOfWeek,
    hour: number,
): ChannelProfile[] | null {
    let dayOfWeekProfiles = channelProfiles.find((d) => d.dayOfWeek === dayOfWeek)
    let bandProfiles = dayOfWeekProfiles?.bandProfiles.find((bp) => bp.band === band)
    let hourProfiles = bandProfiles?.hourlyProfiles.find((hp) => hp.hourId === hour)
    return hourProfiles?.channelProfiles || null
}

export const ChannelProfilesView = ({ serialNumber }: { serialNumber: string }) => {
    const dispatch = useDispatch<AppDispatch>()
    const channelProfilesState = useSelector(selectChannelProfiles)
    const [band, setBand] = useState<WifiBand>("2.4GHz")
    const [dayOfWeek, setDayOfWeek] = useState<DayOfWeek>(DayOfWeek.Monday)
    const [hourOfDay, setHourOfDay] = useState<number>(0)
    const [filteredProfiles, setFilteredProfiles] = useState<ChannelProfile[] | null>()

    const refreshProfileData = () => {
        dispatch(getChannelProfiles({ serialNumber: serialNumber }))
    }

    useEffect(refreshProfileData, [serialNumber])

    useEffect(() => {
        if (channelProfilesState.profiles != null) {
            setFilteredProfiles(filterChannelProfiles(channelProfilesState.profiles, band, dayOfWeek, hourOfDay))
        }
    }, [channelProfilesState, band, dayOfWeek, hourOfDay])

    return (
        <>
            <Header>Channel Profiles</Header>
            <div style={{ display: "flex" }}>
                <Dropdown
                    selection
                    options={bandDropdownOptions}
                    value={band}
                    onChange={(_, data) => setBand(data.value as WifiBand)}
                />
                <Dropdown
                    selection
                    options={dayOfWeekDropdownOptions}
                    value={dayOfWeek}
                    onChange={(_, data) => setDayOfWeek(data.value as DayOfWeek)}
                />
                <Dropdown
                    selection
                    options={hourOfDayDropdownOptions}
                    value={hourOfDay}
                    onChange={(_, data) => setHourOfDay(data.value as number)}
                />
                <Button circular style={{ marginLeft: "auto" }} icon="refresh" onClick={refreshProfileData} />
            </div>
            <div>
                <Dimmer inverted active={channelProfilesState.loading}>
                    <Loader active={channelProfilesState.loading} content="Loading" />
                </Dimmer>
                {channelProfilesState.lastError != null ? (
                    <Message warning>
                        <Icon name="warning" />
                        {channelProfilesState.lastError}
                    </Message>
                ) : filteredProfiles ? (
                    <Table celled>
                        <TableHeader>
                            <TableRow>
                                <TableHeaderCell>Channel</TableHeaderCell>
                                <TableHeaderCell>Bandwidth</TableHeaderCell>
                                <TableHeaderCell>Fitness</TableHeaderCell>
                                <TableHeaderCell>Observation Count</TableHeaderCell>
                                <TableHeaderCell>Last Observation Date</TableHeaderCell>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {filteredProfiles.map((profile) => (
                                <TableRow>
                                    <TableCell>{profile.channel}</TableCell>
                                    <TableCell>{profile.bandwidth}</TableCell>
                                    <TableCell>{profile.fitnessValue}</TableCell>
                                    <TableCell>{profile.observationCount}</TableCell>
                                    <TableCell>{profile.lastObservationDate}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                ) : (
                    <Message warning>
                        <Icon name="warning" />
                        {"No profile data found for this hour"}
                    </Message>
                )}
            </div>
        </>
    )
}
