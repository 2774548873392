import { Icon, Menu, Tab, TabPane } from "semantic-ui-react"
import { ChannelProfilesView } from "./ChannelProfilesView"

export const CpeMiscellaneousView = ({ serialNumber }: { serialNumber: string }) => {
    return (
        <Tab
            menu={{ fluid: true, vertical: true }}
            grid={{ paneWidth: 14, tabWidth: 2 }}
            panes={[
                {
                    menuItem: (
                        <Menu.Item key="channelProfiles">
                            <Icon name="wifi" />
                            Channel Profiles
                        </Menu.Item>
                    ),
                    render: () => (
                        <TabPane>
                            <ChannelProfilesView serialNumber={serialNumber} />
                        </TabPane>
                    ),
                },
            ]}
        />
    )
}
