import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { tryFetch } from "../../utils/api"
import { RootState } from "../store"
import { DayOfWeek } from "../types/dayOfWeek"

export interface ScheduleParameters {
    enabled: boolean
    days: DayOfWeek[]
    startTime: string
    endTime: string
}

export interface Schedule extends ScheduleParameters {
    id: string
}

export interface ScheduleEditState {
    profileId: string
    scheduleId: string
    markedForDeleted: boolean
    updates: ScheduleUpdates
}

export interface ParentalControlParameters {
    name: string
    enabled: boolean
    macAddresses: string[]
}

export interface CreateParentalControl extends ParentalControlParameters {
    schedules: ScheduleParameters[]
}

export interface ParentalControlProfile extends ParentalControlParameters {
    id: string
    schedules: Schedule[]
}

export interface ParentalControlState {
    enabled: boolean
    profiles: Array<ParentalControlProfile>
    loadingProfiles: Record<string, boolean>
    creatingNewProfile: boolean
    errorMessage: string | null
}

const createInitialState = (): ParentalControlState => {
    return {
        enabled: false,
        profiles: [],
        errorMessage: null,
        loadingProfiles: {},
        creatingNewProfile: false,
    }
}

export interface ScheduleUpdates {
    enabled?: boolean
    days?: DayOfWeek[]
    startTime?: string
    endTime?: string
}

export interface UpdateSchedulePayload {
    serialNumber: string
    profileId: string
    scheduleId: string
    updates: ScheduleUpdates
}

export const updateSchedule = createAsyncThunk(
    "updateSchedule",
    async (payload: UpdateSchedulePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(
            `/v1/devices/${payload.serialNumber}/parental-control/profiles/${payload.profileId}/schedules/${payload.scheduleId}`,
            {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload.updates),
            },
        )
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Schedule
    },
)

export interface CreateSchedulePayload {
    serialNumber: string
    profileId: string
    parameters: ScheduleParameters
}

export const createSchedule = createAsyncThunk(
    "createSchedule",
    async (payload: CreateSchedulePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(
            `/v1/devices/${payload.serialNumber}/parental-control/profiles/${payload.profileId}/schedules`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload.parameters),
            },
        )
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as Schedule
    },
)

export interface DeleteSchedulePayload {
    serialNumber: string
    profileId: string
    scheduleId: string
}

export const deleteSchedule = createAsyncThunk(
    "deleteSchedule",
    async (payload: DeleteSchedulePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(
            `/v1/devices/${payload.serialNumber}/parental-control/profiles/${payload.profileId}/schedules/${payload.scheduleId}`,
            {
                method: "DELETE",
            },
        )
        if (rsp.status >= 400) {
            return rejectWithValue(false)
        }
        return true
    },
)

export const fetchParentalControlProfiles = createAsyncThunk(
    "fetchParentalControlProfiles",
    async (payload: { serialNumber: string }, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/parental-control`, {
            method: "GET",
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body
    },
)

export interface UpdateParentalControlConfig {
    enabled?: boolean | null
}

export interface UpdateParentalControlConfigPayload {
    serialNumber: string
    updates: UpdateParentalControlConfig
}

export const updateParentalControlConfig = createAsyncThunk(
    "updateParentalControlConfig",
    async (payload: UpdateParentalControlConfigPayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/parental-control`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.updates),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body
    },
)

export interface ParentalControlUpdates {
    name?: string | null
    enabled?: boolean | null
    macAddresses?: string[] | null
}

export interface UpdateParentalControlProfilePayload {
    serialNumber: string
    profileId: string
    updates: ParentalControlUpdates
}

export const updateParentalControlProfile = createAsyncThunk(
    "updateParentalControlProfile",
    async (payload: UpdateParentalControlProfilePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/parental-control/profiles/${payload.profileId}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.updates),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as ParentalControlProfile
    },
)

export interface CreateParentalControlProfilePayload {
    serialNumber: string
    parameters: CreateParentalControl
}

export const createParentalControlProfile = createAsyncThunk(
    "createParentalControlProfile",
    async (payload: CreateParentalControlProfilePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/parental-control/profiles`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.parameters),
        })
        let body = await rsp.json()
        if (rsp.status >= 400) {
            return rejectWithValue(body)
        }
        return body as ParentalControlProfile
    },
)

export interface DeleteParentalControlProfilePayload {
    serialNumber: string
    profileId: string
}

export const deleteParentalControlProfile = createAsyncThunk(
    "deleteParentalControlProfile",
    async (payload: DeleteParentalControlProfilePayload, { rejectWithValue }) => {
        let rsp = await tryFetch(`/v1/devices/${payload.serialNumber}/parental-control/profiles/${payload.profileId}`, {
            method: "DELETE",
        })
        if (rsp.status >= 400) {
            return rejectWithValue(false)
        }
        return true
    },
)

export const parentalControlSlice = createSlice({
    name: "parentalControlSlice",
    initialState: createInitialState(),
    reducers: {},
    extraReducers: (builder) => {
        // UPDATE CONFIG
        builder.addCase(updateParentalControlConfig.fulfilled, (state, action) => {
            state.enabled = action.payload.enabled
        })
        // LIST CONFIG AND PROFILES
        builder.addCase(fetchParentalControlProfiles.pending, (state, action) => {
            state.profiles = []
        })
        builder.addCase(fetchParentalControlProfiles.fulfilled, (state, action) => {
            state.profiles = action.payload.profiles
            state.enabled = action.payload.enabled
        })
        builder.addCase(fetchParentalControlProfiles.rejected, (state, action) => {
            state.profiles = []
        })
        // UPDATE PROFILE
        builder.addCase(updateParentalControlProfile.pending, (state, action) => {
            state.loadingProfiles[action.meta.arg.profileId] = true
            console.log("Loading: " + JSON.stringify(state.loadingProfiles))
        })
        builder.addCase(updateParentalControlProfile.fulfilled, (state, action) => {
            const profileId = action.meta.arg.profileId
            let idx = state.profiles.findIndex((profile) => profile.id === profileId)
            state.profiles[idx] = action.payload
            delete state.loadingProfiles[profileId]
            console.log("Loading: " + JSON.stringify(state.loadingProfiles))
        })
        builder.addCase(updateParentalControlProfile.rejected, (state, action) => {
            const profileId = action.meta.arg.profileId
            delete state.loadingProfiles[profileId]
        })
        // CREATE PROFILE
        builder.addCase(createParentalControlProfile.pending, (state, action) => {
            state.creatingNewProfile = true
        })
        builder.addCase(createParentalControlProfile.fulfilled, (state, action) => {
            state.profiles.push(action.payload)
            state.creatingNewProfile = false
        })
        builder.addCase(createParentalControlProfile.rejected, (state, action) => {
            state.creatingNewProfile = false
        })
        // DELETE PROFILE
        builder.addCase(deleteParentalControlProfile.pending, (state, action) => {
            state.loadingProfiles[action.meta.arg.profileId] = true
        })
        builder.addCase(deleteParentalControlProfile.fulfilled, (state, action) => {
            const profileId = action.meta.arg.profileId
            state.profiles = state.profiles.filter((profile) => profile.id !== profileId)
            delete state.loadingProfiles[profileId]
        })
        builder.addCase(deleteParentalControlProfile.rejected, (state, action) => {
            const profileId = action.meta.arg.profileId
            delete state.loadingProfiles[profileId]
        })

        // CREATE SCHEDULE
        builder.addCase(createSchedule.pending, (state, action) => {
            state.loadingProfiles[action.meta.arg.profileId] = true
            console.log("Loading: " + JSON.stringify(state.loadingProfiles))
        })
        builder.addCase(createSchedule.fulfilled, (state, action) => {
            const profileId = action.meta.arg.profileId
            let idx = state.profiles.findIndex((profile) => profile.id === profileId)
            let schedules = state.profiles[idx].schedules
            state.profiles[idx].schedules = [...schedules, action.payload]

            delete state.loadingProfiles[profileId]
        })
        builder.addCase(createSchedule.rejected, (state, action) => {
            const profileId = action.meta.arg.profileId
            delete state.loadingProfiles[profileId]
        })

        // UPDATE SCHEDULE
        builder.addCase(updateSchedule.pending, (state, action) => {
            state.loadingProfiles[action.meta.arg.profileId] = true
            console.log("Loading: " + JSON.stringify(state.loadingProfiles))
        })
        builder.addCase(updateSchedule.fulfilled, (state, action) => {
            const profileId = action.meta.arg.profileId
            const scheduleId = action.payload.id
            let idx = state.profiles.findIndex((profile) => profile.id === profileId)
            let scheduleIdx = state.profiles[idx].schedules.findIndex((schedule) => schedule.id === scheduleId)

            state.profiles[idx].schedules[scheduleIdx] = action.payload

            delete state.loadingProfiles[profileId]
        })
        builder.addCase(updateSchedule.rejected, (state, action) => {
            const profileId = action.meta.arg.profileId
            delete state.loadingProfiles[profileId]
        })

        // DELETE SCHEDULE
        builder.addCase(deleteSchedule.pending, (state, action) => {
            state.loadingProfiles[action.meta.arg.profileId] = true
            console.log("Loading: " + JSON.stringify(state.loadingProfiles))
        })
        builder.addCase(deleteSchedule.fulfilled, (state, action) => {
            const profileId = action.meta.arg.profileId
            const scheduleId = action.meta.arg.scheduleId
            let idx = state.profiles.findIndex((profile) => profile.id === profileId)
            let schedules = state.profiles[idx].schedules
            state.profiles[idx].schedules = schedules.filter((schedule) => schedule.id !== scheduleId)
            delete state.loadingProfiles[profileId]
        })
        builder.addCase(deleteSchedule.rejected, (state, action) => {
            const profileId = action.meta.arg.profileId
            delete state.loadingProfiles[profileId]
        })
    },
})

export const parentalControlConfigReducer = parentalControlSlice.reducer
export const selectParentalControlConfig = (state: RootState) => state.parentalControlConfig
